import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { IGetStreamTypes } from './models';

@Injectable({
  providedIn: 'root',
})
export class StreamTypeService {
  private apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getStreamTypes(webAddress: string): Observable<IGetStreamTypes> {
    return this.http.get<IGetStreamTypes>(
      `${this.apiUrl}stream_type/public/?web_address=${webAddress}&page=0&page_size=100`,
    );
  }
}
