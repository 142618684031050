import { StreamType } from '../shared/interfaces/stream';
import { PortalTemplate } from './../shared/interfaces/portal-template';

export class GetPortalTemplate {
  static readonly type = '[Portal] Get portal template';

  constructor(public payload: PortalTemplate) {}
}

export class GetStreamTypes {
  static readonly type = '[Portal] Get stream types';

  constructor(public payload: StreamType[]) {}
}
