<section class="contacts-container">
  <a
    class="logo"
    routerLink="/"
  >
    <img
      [src]="logo"
      alt="Company logo"
    />
  </a>
  <div class="contacts">
    <a
      [href]="'https://maps.google.com/?q=' + portalTemplate.address"
      target="_blank"
      class="contact-item"
    >
      <i class="bx bxs-map-pin"></i>
      <h6>{{ 'field.address' | translate }}</h6>
      <span>{{ portalTemplate.address }}</span>
    </a>
    <a
      [href]="'tel:' + portalTemplate.phone_number"
      class="contact-item"
    >
      <i class="bx bx-phone-call"></i>
      <h6>{{ 'field.phone' | translate }}</h6>
      <span>{{ portalTemplate.phone_number }}</span>
    </a>
    <a
      [href]="'mailto:' + portalTemplate.email"
      class="contact-item"
    >
      <i class="bx bx-envelope"></i>
      <h6>{{ 'field.email' | translate }}</h6>
      <span>{{ portalTemplate.email }}</span>
    </a>
    <a
      [href]="portalTemplate.website"
      target="_blank"
      class="contact-item"
    >
      <i class="bx bx-link"></i>
      <h6>{{ 'field.website' | translate }}</h6>
      <span>{{ portalTemplate.website }}</span>
    </a>
  </div>
</section>
