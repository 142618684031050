import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const headers = new HttpHeaders({ skipAuth: 'true' });

    return this.http.get(`https://webtvdev.blob.core.windows.net/media/i18n/${lang}.json`, { headers });
  }
}
