<nav class="navbar-container">
  <ul>
    <li>
      <a
        routerLink="/"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="bx bx-home-alt"></i>
        <span>{{ 'navigation.home' | translate }}</span>
      </a>
    </li>
    <li>
      <a
        routerLink="/live-broadcasts"
        routerLinkActive="active"
      >
        <i class="bx bx-station"></i>
        <span>{{ 'navigation.liveBroadcasts' | translate }}</span>
      </a>
    </li>
    <li>
      <a
        routerLink="/all-videos"
        routerLinkActive="active"
      >
        <i class="bx bx-movie-play"></i>
        <span>{{ 'navigation.allVideos' | translate }}</span>
      </a>
    </li>
  </ul>
</nav>
