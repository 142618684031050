<ng-container *ngIf="!isUnavailable; else unavailable">
  <header
    [style.fontFamily]="portalTemplate.font + ', sans-serif'"
    *ngIf="!isLoadingTemplate"
  >
    <app-header [data]="portalTemplate"></app-header>
  </header>
  <main
    class="main-wrapper"
    *ngIf="!isLoadingTemplate"
    [style.fontFamily]="portalTemplate.font + ', sans-serif'"
  >
    <router-outlet></router-outlet>
  </main>
  <footer
    class="footer-wrapper"
    *ngIf="!isLoadingTemplate"
    [style.fontFamily]="portalTemplate.font + ', sans-serif'"
  >
    <app-contacts [data]="portalTemplate"></app-contacts>
    <app-footer></app-footer>
  </footer>
  
  <div
    class="navbar-wrapper"
    *ngIf="!isLoadingTemplate"
    [style.fontFamily]="portalTemplate.font + ', sans-serif'"
  >
    <app-navbar></app-navbar>
  </div>
</ng-container>

<div
  class="spinner-wrapper"
  *ngIf="isLoadingTemplate"
>
  <app-spinner size="lg"></app-spinner>
</div>

<ng-template #unavailable>
  <div class="unavailable">
    Sorry, the service is currently unavailable.
  </div>
</ng-template>