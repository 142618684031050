import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetStreamTypes } from './app.action';
import { StreamType } from '../shared/interfaces/stream';

@State<StreamType[]>({
  name: 'streamTypes',
  defaults: [],
})
export class StreamTypeState {
  @Selector()
  static streamType(state: StreamType[]): StreamType[] {
    return [...state];
  }

  @Action(GetStreamTypes)
  setStreamType({ setState }: StateContext<StreamType[]>, { payload }: GetStreamTypes) {
    setState(payload);
  }
}
