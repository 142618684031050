import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetPortalTemplate } from './app.action';
import { PortalTemplate } from '../shared/interfaces/portal-template';

@State<PortalTemplate>({
  name: 'themeState',
  defaults: {
    name_of_tab: '',
    font: 'AeonikPro',
    logo_url: '',
    logo_link: '',
    analytics_url: 'https://www.skien.kommune.no',
    availability_statement_url: 'https://www.skien.kommune.no',
    language: 'en',
    address: '',
    phone_number: '',
    email: '',
    website: '',
    header_text_color: '#fff',
    header_text: 'Welcome',
    page_header_background: '#004851',
    page_body_background: '#f2f4f4',
    button_corners_style: 'Round',
    button_color: '#000',
    button_text_color: '#fff',
  },
})
export class ThemeState {
  @Selector()
  static portalTemplate(state: PortalTemplate): PortalTemplate {
    return state;
  }

  @Action(GetPortalTemplate)
  setPortalTemplate({ patchState }: StateContext<PortalTemplate>, { payload }: GetPortalTemplate) {
    patchState({ ...payload });
  }
}
