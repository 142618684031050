import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { PortalTemplate } from '../../interfaces/portal-template';
import { FilesService } from '../../services/files';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  public portalTemplate!: PortalTemplate;
  public logo: string = '';

  @Input() set data(value: PortalTemplate) {
    if (!value) {
      return;
    }

    this.portalTemplate = value;
    this._getImage(value.logo_url);
    this._cdr.markForCheck();
  }

  private readonly _fiesService = inject(FilesService);
  private readonly _cdr = inject(ChangeDetectorRef);

  private _getImage(filename: string): void {
    if (!filename) {
      return;
    }

    this._fiesService
      .downloadFile(filename)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          this.logo = response as string;
          this._cdr.markForCheck();
        },
      });
  }
}
