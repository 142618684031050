import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetVideoPlayerState } from './video.action';
import { VideoPlayer } from './interfaces';

@State<VideoPlayer>({
  name: 'videoPlayerState',
  defaults: {
    startWith: undefined,
  },
})
export class VideoPlayerState {
  @Selector()
  static videoPlayerState(state: VideoPlayer): VideoPlayer {
    return state;
  }

  @Action(GetVideoPlayerState)
  setVideoPlayerState({ patchState }: StateContext<VideoPlayer>, { payload }: GetVideoPlayerState) {
    patchState({ ...payload });
  }
}
