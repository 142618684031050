import { environment } from './../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PortalTemplate } from './shared/interfaces/portal-template';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getPortalTemplate(webAddress: string): Observable<PortalTemplate> {
    return this.http.get<PortalTemplate>(`${this.apiUrl}portal_template/public/?web_address=${webAddress}`);
  }
}
