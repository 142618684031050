import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface FileDownloadResponse {
  download_token: string;
}

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private _apiUrl: string = environment.apiUrl;
  private _storageUrl: string = environment.storageUrl;

  private readonly _http = inject(HttpClient);

  public downloadFile(file_name: string): Observable<FileDownloadResponse[] | string> {
    const webAddress = window.location.origin;
    const params = new HttpParams().append('web_address', webAddress).append('file_name', file_name);

    return this._http.post<FileDownloadResponse[]  | string>(`${this._apiUrl}file/download_public/`, {}, { params })
      .pipe(
        map((res: FileDownloadResponse[]  | string) => {
          if (typeof res === 'string') {
            return res;
          }

          return `${this._storageUrl}/download/${res[0].download_token}/`;
        }),
      );
  }
}
